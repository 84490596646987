<template>
  <div class="not-found p-4">
    <h1>Page not found.</h1>
    <div class="text-blue">
      <router-link to="/">Return home</router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
