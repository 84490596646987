<template>
  <page-container name="2col">
    <chapters-sidebar
      slot="sidebar"
      :chapters="currentBookChapters"
    ></chapters-sidebar>
    <router-view />
  </page-container>
</template>

<script>
import { mapGetters } from "vuex";

import PageContainer from "@/components/system/PageContainer.vue";
import ChaptersSidebar from "@/components/books/ChaptersSidebar.vue";

export default {
  components: { PageContainer, ChaptersSidebar },
  computed: {
    ...mapGetters(["currentBookChapters"]),
  },
};
</script>
