import api from "@/store/api";
import router from "@/router";
import { propEq, complement, filter } from "ramda";

const isActive = propEq("deleted", false);
const isDeleted = complement(isActive);

const activeFilter = filter(isActive);
const deletedFilter = filter(isDeleted);

const state = {
  books: [],
  currentBookId: null,
  currentChapterId: null,
  currentBook: null,
  currentChapter: null,
};

const getters = {
  books(state) {
    return state.books;
  },
  activeBooks(state, getters) {
    return activeFilter(getters.books);
  },
  deletedBooks(state, getters) {
    return deletedFilter(getters.books);
  },
  currentBookId(state) {
    return state.currentBookId;
  },
  currentBook(state) {
    return state.currentBook;
  },
  currentBookTitle(state) {
    if (state.currentBook) return state.currentBook.book_title;
    return "";
  },
  currentBookChapters(state, getters) {
    if (getters.currentBook) return getters.currentBook.chapters;
    else return [];
  },
  currentChapterId(state) {
    return state.currentChapterId;
  },
  currentChapter(state) {
    return state.currentChapter;
  },
  chapterIsLoaded(state, getters) {
    return (
      getters.currentChapter &&
      getters.currentChapter.id === getters.currentChapterId
    );
  },
};

const actions = {
  async loadBooks({ commit, dispatch, getters }) {
    try {
      const response = await api.get("author/books");
      commit("setBooks", response.data);
    } catch (err) {
      console.log(err);
    }
  },
  updateRouteParams({ commit, dispatch, getters }, params) {
    commit("updateRouteParams", params);
    dispatch("refresh");
  },
  refresh({ commit, dispatch, getters }) {
    dispatch("loadCurrentBook");
    dispatch("loadCurrentChapter");
  },
  loadCurrentChapter({ commit, dispatch, getters }) {
    if (!getters.currentChapterId) return;
    api.get(`author/chapters/${getters.currentChapterId}`).then((response) => {
      commit("setCurrentChapter", response.data[0]);
    });
  },
  loadCurrentBook({ commit, dispatch, getters }) {
    if (!getters.currentBookId) return;
    api.get(`author/books/${getters.currentBookId}`).then((response) => {
      commit("setCurrentBook", response.data[0]);
    });
  },
  addChapter({ commit, dispatch, getters }) {
    api.post(`author/chapters/`, { title: "Untitled" }).then((result) => {
      const c_id = result.data.id;
      api
        .post(`author/books/${getters.currentBookId}/chapters/`, {
          chapter_id: c_id,
        })
        .then(() => {
          router.push(`/books/${getters.currentBookId}/chapters/${c_id}`);
        });
    });
  },
  async renameCurrentChapter({ commit, dispatch, getters }, newTitle) {
    const trimmed = newTitle.trim();
    if (trimmed == 0) return;
    await api.put(`author/chapters/${getters.currentChapterId}`, {
      title: trimmed,
    });
    dispatch("refresh");
  },
  async publishBook({ commit, dispatch, getters }, { id }) {
    await api.put(`admin/books/${id}/publish`);
    dispatch("loadBooks");
  },
  async unpublishBook({ commit, dispatch, getters }, { id }) {
    await api.put(`admin/books/${id}/unpublish`);
    dispatch("loadBooks");
  },
  async publishChapter({ commit, dispatch, getters }) {
    await api.put(`author/chapters/${getters.currentChapterId}/publish`);
    dispatch("refresh");
  },
  async unpublishChapter({ commit, dispatch, getters }) {
    await api.put(`author/chapters/${getters.currentChapterId}/unpublish`);
    dispatch("refresh");
  },
  async deleteCurrentChapter({ commit, dispatch, getters }) {
    if (!getters.currentChapterId) return;
    await api.delete(`author/chapters/${getters.currentChapterId}`);
    dispatch("refresh");
    router.push(`/books/${getters.currentBookId}`);
  },
  async deleteBook({ commit, dispatch, getters }, { id }) {
    try {
      await api.delete(`author/books/${id}`);
      dispatch("loadBooks");
    } catch (err) {
      console.log(err);
    }
  },
  async restoreBook({ commit, dispatch, getters }, { id }) {
    try {
      await api.post("author/books/restore", { id });
      dispatch("loadBooks");
    } catch (err) {
      console.log(err);
    }
  },
};

const mutations = {
  setBooks(state, books) {
    if (books) state.books = books;
    else state.books = [];
  },
  updateRouteParams(state, { b_id, c_id }) {
    if (b_id) state.currentBookId = parseInt(b_id);
    else state.currentBookId = null;
    if (c_id) state.currentChapterId = parseInt(c_id);
    else state.currentChapterId = null;
  },
  setCurrentChapter(state, chapter) {
    state.currentChapter = chapter;
  },
  setCurrentBook(state, book) {
    state.currentBook = book;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
