<template>
  <page-container name="basic">
    <div class="title text-4xl mb-4">What's the name of the book?</div>
    <div class="mb-8">
      <input
        type="text"
        placeholder="Enter title"
        class="w-1/2 text-4xl"
        v-model="title"
        @keyup.enter="addBook"
        autofocus
      />
    </div>
    <button @click="addBook" class="px-4 py-2 border rounded">Create book</button>
  </page-container>
</template>

<script>
import PageContainer from "@/components/system/PageContainer.vue";

export default {
  data() {
    return { title: "" };
  },
  components: {
    PageContainer
  },
  methods: {
    async addBook() {
      try {
        const response = await this.$http.post(`author/books`, {
          title: this.title
        });
        const id = response.data.id;
        this.$router.push(`/books/${id}`);
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
