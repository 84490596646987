<template>
  <div
    @click="$emit('click')"
    class="rounded-full bg-overpale px-4 text-sm font-bold inline-block leading-10 cursor-pointer"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ButtonSmall",
};
</script>

<style lang="scss" scoped>
</style>
