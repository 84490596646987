<!-- Add your own bg-colour or border on the component tag -->
<template>
  <div class="inline-block px-2 py-1 text-xs font-bold rounded">
    <slot />
  </div>
</template>

<script>
export default {
  name: "SmallLabel",
};
</script>