<template>
  <div v-if="currentChapter">
    <div class="flex items-center">
      <template v-if="!renaming">
        <div @click="renameChapter" class="chapterName hover:bg-white">
          {{ title }}
        </div>
      </template>
      <template v-else>
        <input
          type="text"
          class="chapterName"
          placeholder="Chapter title"
          v-model="newTitle"
          ref="chapterNameInput"
          @keydown.enter="commitRename"
          @keydown.esc="cancelRename"
        />
      </template>
    </div>
    <!-- Summary -->
    <!-- <div class="text-lg mb-8">Chapter summary</div> -->
    <!-- Options -->
    <div class="mb-4 flex items-center">
      <toggle-switch
        :opts="[
          { label: 'Draft', action: 'unpublish', active: !Boolean(published) },
          { label: 'Published', action: 'publish', active: Boolean(published) },
        ]"
        @unpublish="unpublish"
        @publish="publish"
      />
      <button-round @click="toggleMenu" class="ml-4 hover:bg-overpale">
        <img src="@/assets/img/ellipsis.svg" />
        <menu-list v-if="showMenu">
          <menu-item @click="deleteChapter">Delete chapter</menu-item>
        </menu-list>
      </button-round>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ToggleSwitch from "@/components/system/ToggleSwitch.vue";
import ButtonRound from "@/components/system/ButtonRound.vue";
import MenuList from "@/components/system/MenuList.vue";
import MenuItem from "@/components/system/MenuItem.vue";

export default {
  props: {},
  data() {
    return {
      newTitle: "",
      renaming: false,
      showMenu: false,
    };
  },
  components: { ToggleSwitch, ButtonRound, MenuList, MenuItem },
  computed: {
    ...mapGetters(["currentChapter"]),
    title() {
      return this.currentChapter.title;
    },
    published() {
      return this.currentChapter.published;
    },
  },
  methods: {
    renameChapter() {
      this.newTitle = this.title;
      this.renaming = true;
      this.$nextTick(() => {
        this.$refs.chapterNameInput.focus();
      });
    },
    commitRename() {
      const result = this.$store.dispatch(
        "renameCurrentChapter",
        this.newTitle
      );
      this.renaming = false;
    },
    cancelRename() {
      this.renaming = false;
      this.newTitle = this.title;
    },
    deleteChapter() {
      this.$store.dispatch("deleteCurrentChapter");
    },
    unpublish() {
      if (this.published) this.$store.dispatch("unpublishChapter");
    },
    publish() {
      if (!this.published) this.$store.dispatch("publishChapter");
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
.chapterName {
  @apply text-xl;
  @apply font-bold;
  @apply flex-grow;
  @apply rounded-lg;
  @apply mb-2;
  @apply p-4;
  @apply -ml-4;
  @apply -mt-4;
  @apply border-none;
}
</style>