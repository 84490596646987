<template>
  <div @click="clicked" :class="{ 'cursor-pointer' : to }" class="logo inline-block">
    <img src="@/assets/img/logo.png" />
  </div>
</template>

<script>
export default {
  props: { to: Object },
  methods: {
    clicked() {
      if (this.to) this.$router.push(this.to).catch(err => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.logo {
  width: 80px;
}
</style>
