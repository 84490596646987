<template>
  <div class="p-4 border border-red mb-8 rounded font-bold">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
};
</script>