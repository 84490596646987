<template>
  <div
    @click="$emit('click')"
    class="px-4 py-2 font-bold hover:bg-green-400 hover:text-white"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MenuItem",
};
</script>

<style lang="scss" scoped>
</style>
