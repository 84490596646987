<!-- Replace this with the real component from primer-client, copied into the shared folder -->
<template>
  <div class="preview-chapter-container">
    <div class="preview-chapter">
      <div class="text-lg" v-html="previewHTML"></div>
      <div class="preview-nav prev" @click="previewPrev"></div>
      <div class="preview-nav next" @click="previewNext"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { splitBuilds } from "@/helpers/note-helpers.js";

export default {
  props: {},
  data() {
    return {
      previewIndex: 0,
    };
  },
  computed: {
    ...mapGetters("notes", ["notes"]),
    previewHTML() {
      if (!this.splitNotes || this.splitNotes.length == 0) return;
      const body = this.splitNotes[this.previewIndex].body;
      return this.$md.makeHtml(body);
    },
    splitNotes() {
      if (!this.notes) return;
      return splitBuilds(this.notes);
    },
  },
  methods: {
    previewPrev() {
      if (this.previewIndex == 0) return;
      this.previewIndex = this.previewIndex - 1;
    },
    previewNext() {
      if (this.previewIndex == this.splitNotes.length - 1) return;
      this.previewIndex = this.previewIndex + 1;
    },
  },
  watch: {
    $route(to, from) {
      this.previewIndex = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-chapter-container {
  @apply relative;
  @apply h-full;
}

.preview-chapter {
  @apply select-none;
}

.preview-nav {
  // background: pink;
  opacity: 0.2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 30%;
  &.next {
    width: 70%;
    left: unset;
    right: 0;
  }
}
</style>