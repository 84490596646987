<template>
  <div class="flashcard relative">
    <div class="side border-b flex">
      <span class="label">
        <strong>Front</strong>
      </span>
      <span v-html="front"></span>
    </div>
    <div class="side border-b flex">
      <span class="label">
        <strong>Back</strong>
      </span>
      <span v-html="back"></span>
    </div>
    <div class="side bg-pale flex">
      <span class="label">
        <strong>Hint</strong>
      </span>
      <span v-html="hint"></span>
    </div>
    <div class="delete" @click="deleteFlashcard">
      <author-icon name="delete" class="text-grey w-4 h-4 hover:text-red cursor-pointer" />
    </div>
  </div>
</template>

<script>
import AuthorIcon from "@/components/AuthorIcon.vue";

export default {
  props: {
    flashcard: Object,
    note_id: Number
  },
  components: {
    AuthorIcon
  },
  data() {
    return {};
  },
  methods: {
    deleteFlashcard() {
      this.$store.dispatch("notes/deleteFlashcard", {
        flashcard_id: this.flashcard.id,
        note_id: this.note_id
      });
    }
  },
  computed: {
    front() {
      return this.$md.makeHtml(this.flashcard.front);
    },
    back() {
      return this.$md.makeHtml(this.flashcard.back);
    },
    hint() {
      return this.$md.makeHtml(this.flashcard.hint);
    }
  },
  mounted() {},
  watch: {}
};
</script>

<style scoped>
.flashcard {
  @apply border;
  @apply border-blue;
  @apply border-2;
  @apply bg-white;
  @apply rounded;
  @apply mt-4;
  @apply overflow-hidden;
}

.side {
  @apply p-2;
}

.label {
  min-width: 50px;
  display: inline-block;
  @apply text-blue;
}

.delete {
  @apply absolute;
  @apply top-0;
  @apply right-0;
  @apply p-3;
}
</style>