<template>
  <div
    @click="$emit('click')"
    class="absolute z-10 top-12 right-0 bg-white rounded-xl w-64 py-2 shadow-lg text-sm overflow-hidden"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MenuList",
};
</script>

<style lang="scss" scoped>
</style>
