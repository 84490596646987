<template>
  <div class="bg-pale min-h-screen pb-10">
    <div class="max-w-3xl mx-auto px-4">
      <primer-logo class="mt-10 mb-8" />
      <error-message v-if="errorMsg">{{ errorMsg }}</error-message>
      <new-book-link class="mb-8">New book</new-book-link>
      <book-link v-for="b in activeBooks" :book="b" :key="b.id"></book-link>
      <div
        class="py-4 px-2 cursor-pointer mt-6 font-bold text-blue"
        @click="showDeleted = !showDeleted"
      >
        <template v-if="!showDeleted">Show</template>
        <template v-else>Hide</template> deleted books
      </div>
      <div v-if="showDeleted">
        <book-link v-for="b in deletedBooks" :book="b" :key="b.id"></book-link>
      </div>
    </div>
  </div>
</template>

<script>
import NewBookLink from "@/components/books/NewBookLink.vue";
import BookLink from "@/components/books/BookLink.vue";
import ErrorMessage from "@/components/system/ErrorMessage.vue";
import PrimerLogo from "@/components/system/PrimerLogo.vue";

import { mapGetters } from "vuex";

export default {
  components: { NewBookLink, BookLink, ErrorMessage, PrimerLogo },
  data() {
    return { showDeleted: false, errorMsg: null };
  },
  computed: {
    ...mapGetters(["books", "activeBooks", "deletedBooks"]),
  },
  methods: {},
  async mounted() {
    try {
      await this.$store.dispatch("loadBooks");
    } catch (err) {
      this.errorMsg = "Could not load books. Reload the page to try again.";
    }
  },
};
</script>