<template>
  <div
    @click="clicked"
    :class="{ 'cursor-pointer hover:bg-palehover' : to }"
    class="card bg-white overflow-hidden shadow rounded-lg"
  >
    <div class="px-4 py-5 sm:p-6 font-bold">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    to: Object
  },
  methods: {
    clicked() {
      if (this.to) this.$router.push(this.to);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
