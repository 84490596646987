<template>
  <div
    class="bg-overpale rounded-full p-1 flex w-52 font-bold text-sm select-none cursor-pointer"
  >
    <div
      @click="$emit(opts[0].action)"
      :class="{ 'bg-white': opts[0].active }"
      class="rounded-full text-center flex-none w-1/2 leading-8"
    >
      {{ opts[0].label }}
    </div>
    <div
      :class="{ 'bg-green-400 text-white': opts[1].active }"
      @click="$emit(opts[1].action)"
      class="rounded-full text-center flex-none w-1/2 leading-8"
    >
      {{ opts[1].label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TogglePublish",
  props: {
    opts: {
      type: Array,
    },
    active: {
      type: Boolean,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
