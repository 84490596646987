<template>
  <div class="text-sm">
    <flashcard
      v-for="f in flashcards"
      :key="f.id"
      :flashcard="f"
      :note_id="note_id"
      class="flashcard"
    ></flashcard>
    <add-flashcard :note_id="note_id" class="mt-4" />
  </div>
</template>

<script>
import Flashcard from "@/components/Flashcard.vue";
import AddFlashcard from "@/components/AddFlashcard.vue";

export default {
  components: { Flashcard, AddFlashcard },
  props: {
    note_id: Number,
    flashcards: Array
  },
  computed: {
    n_id() {
      if (!this.note_id) return;
      else return this.note_id;
    }
  },
  watch: {}
};
</script>

<style scoped>
</style>