import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import api from "@/store/api";

// Views
import BooksIndex from "../views/BooksIndex.vue";
import BookEdit from "../views/BookEdit.vue";
import BookEditNew from "../views/BookEditNew.vue";
import NewBook from "../views/NewBook.vue";
import NotFound from "../views/NotFound.vue";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
  },
  {
    path: "/",
    redirect: "/books",
    name: "home",
  },
  {
    path: "/books",
    name: "books",
    component: BooksIndex,
  },
  {
    path: "/books/new",
    name: "new-book",
    component: NewBook,
  },
  {
    path: "/books/:b_id",
    name: "book-edit",
    component: BookEditNew,
  },
  {
    path: "/books/:b_id/chapters/:c_id",
    name: "book-edit-chapter",
    component: BookEditNew,
  },
  {
    // 404
    path: "*",
    name: "404",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.dispatch("updateRouteParams", to.params);
  if (to.name == "login" || to.name == "logout") next();
  else if (store.getters.isAuthenticated) next();
  else {
    api
      .get("/auth/authorping")
      .then(() => {
        store.dispatch("setAuthenticated");
        next();
      })
      .catch(() => {
        store.dispatch("setLoggedOut");
        next({ name: "login" });
      });
  }
});

export default router;
