const state = { isAuthenticated: undefined };

const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  setAuthenticated({ commit }) {
    commit("setIsAuthenticated", true);
  },
  setLoggedOut({ commit }) {
    commit("setIsAuthenticated", false);
  }
};

const mutations = {
  setIsAuthenticated(state, value) {
    state.isAuthenticated = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
