<template>
  <p class="mt-2 text-sm leading-5 text-red-600 max-w">
    <slot />
  </p>
</template>

<script>
export default {
  props: []
};
</script>
