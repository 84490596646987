<template>
  <div @click="$emit('click')" class="rounded-full w-10 h-10 cursor-pointer">
    <div class="relative">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonRound",
};
</script>

<style lang="scss" scoped>
</style>
