<template>
  <div class="border-b -ml-4 pl-4" :class="{ 'bg-white': localFocus || !body }">
    <div class="flex relative">
      <!-- Bullet -->
      <div class="pt-4 text-sm leading-6 font-bold text-gray-400">
        <!-- <span>•</span> -->
        <span>{{ number }}</span>
      </div>
      <!-- Preview -->
      <div
        class="preview-note"
        v-show="showPreview"
        v-html="bodyHTML"
        @click="focusNote"
      ></div>
      <!-- Edit -->
      <textarea
        v-show="!showPreview"
        ref="input"
        class="input-note"
        v-model="body"
        placeholder="Type note text here"
        rows="1"
        @focus="textareaFocused"
        @blur="textareaBlurred"
        @keyup.alt.enter.prevent="addNote"
        @keydown.delete="checkDelete"
      ></textarea>
      <div
        :class="{ 'text-copydark': number_of_flashcards }"
        class="px-3 pt-4 text-gray-400 cursor-pointer"
        @click="toggleFlashcards"
      >
        <span
          class="flex items-center justify-around h-6"
          :class="{ secondary: number_of_flashcards === 0 }"
        >
          <span class="mr-2 text-sm" v-show="number_of_flashcards">{{
            number_of_flashcards
          }}</span>
          <author-icon name="cards" class="w-4 h-4" />
        </span>
      </div>
    </div>
    <div v-show="showFlashcards" class="p-4 pt-0">
      <flashcards-group :note_id="note.note_id" :flashcards="flashcards" />
    </div>
  </div>
</template>

<script>
const R = require("ramda");
import autosize from "autosize";

import FlashcardsGroup from "@/components/FlashcardsGroup.vue";
import AuthorIcon from "@/components/AuthorIcon.vue";

export default {
  components: {
    FlashcardsGroup,
    AuthorIcon
  },
  data() {
    return {
      showFlashcards: false,
      localFocus: false
    };
  },
  props: {
    note: Object,
    number: Number,
    focused: Boolean
  },
  computed: {
    showPreview() {
      if (this.localFocus) return false;
      else if (!this.body.length) return false;
      return true;
    },
    flashcards() {
      if (this.note.flashcards)
        return R.reject(R.propEq("deletedInStore", true), this.note.flashcards);
      else return [];
    },
    number_of_flashcards() {
      if (this.flashcards) return this.flashcards.length;
      else return 0;
    },
    bodyHTML() {
      return this.$md.makeHtml(this.body);
    },
    body: {
      get() {
        return this.note.body;
      },
      set(value) {
        autosize.update(this.input);
        this.$store.commit("notes/updateNote", {
          note_id: this.note.note_id,
          body: value
        });
      }
    },
    input() {
      return this.$refs.input;
    }
  },
  methods: {
    focusNote() {
      this.localFocus = true;
    },
    toggleFlashcards() {
      this.showFlashcards = !this.showFlashcards;
    },
    addNote() {
      this.$store.dispatch("notes/addNote", {
        chapter_id: this.note.chapter_id,
        after_note: this.note.note_id
      });
    },
    checkDelete(event) {
      if (!this.body) {
        event.preventDefault(); // so that the delete keypress doesn't execute onto the next-focused note
        this.$store.dispatch("notes/deleteNote", {
          note_id: this.note.note_id
        });
      }
    },
    focusAndResize() {
      this.input.focus();
      autosize.update(this.input);
      this.input.scrollIntoView({ block: "center" });
    },
    focusInput() {
      if (this.focused) this.input.focus();
    },
    textareaFocused() {
      this.localFocus = true;
      this.$store.commit("notes/focusNote", this.note.note_id);
    },
    textareaBlurred() {
      this.localFocus = false;
    }
  },
  mounted() {
    autosize(this.input);
    this.focusInput();
  },
  watch: {
    note(after, before) {
      this.$nextTick(() => {
        autosize.update(this.input);
      });
    },
    focused(after, before) {
      if (after) {
        this.localFocus = true;
        this.$nextTick(() => {
          this.focusAndResize();
        });
      } else {
        this.localFocus = false;
      }
    },
    localFocus(after, before) {
      if (after) {
        this.$nextTick(() => {
          this.focusAndResize();
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.preview-note,
.input-note {
  @apply text-base;
  @apply p-4;
  @apply flex-grow;
}

.input-note {
  @apply border-0;
  resize: none;
}
</style>

<style lang="scss">
// Can't style a v-html with scoped
.preview-note > p {
  margin-bottom: 1.5rem;
}

.preview-note > p:last-of-type {
  margin-bottom: 0;
}

.preview-note img {
  margin-top: 1em;
  max-width: 40%;
}
</style>