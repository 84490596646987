import Vue from "vue";
const throttle = require("lodash.throttle");
import { propEq, findIndex, reject, dec, inc, append, insert } from "ramda";

import api from "@/store/api";

// helper functions
const idEq = propEq("id");
const noteIdEq = propEq("note_id");
const tempIdEq = propEq("temp_id");
const findIdIndex = (id, arr) => findIndex(idEq(id), arr);
const findNoteIdIndex = (id, arr) => findIndex(noteIdEq(id), arr);
const findTempIndex = (id, arr) => findIndex(tempIdEq(id), arr);
const withoutDeleted = reject(propEq("deletedInStore", true));

// api helpers
const apiUpdateNote = throttle((id, body) => {
  api
    .put(`author/notes/${id}`, { body: body })
    .catch((err) => console.log(err));
}, 500);

const state = { notes: null, focusedNote: null };

const getters = {
  notes(state) {
    if (!state.notes) return null;
    return withoutDeleted(state.notes);
  },
  focusedNote(state) {
    if (state.focusedNote === null) return 0;
    if (state.focusedNote > dec(state.notes.length)) return 0;
    return state.focusedNote;
  },
};

const actions = {
  async loadNotes({ commit }, { chapter_id }) {
    const res = await api.get(`user/chapters/${chapter_id}/notes`);
    commit("setNotes", { notes: res.data });
  },
  async addNote({ commit }, { chapter_id, after_note }) {
    // first add to the store with a temp_id
    const temp_id = Date.now();
    console.log(after_note);
    commit("addNoteToStore", { chapter_id, after_note, temp_id });
    // then add to the database
    const res = await api.post(`author/notes`, { chapter_id, after_note });
    const note_id = res.data.id;
    // then update the note in the store with the database ID
    commit("updateTempNoteWithId", { temp_id, note_id });
  },
  deleteNote({ commit, state }, { note_id }) {
    commit("deleteNoteFromStore", note_id);
    api.delete(`author/notes/${note_id}`);
  },
  async addFlashcard({ commit }, { front, back, hint, note_id }) {
    // first add to the store with a temp_id
    const temp_id = Date.now();
    commit("addFlashcardToStore", { front, back, hint, note_id, temp_id });
    // then add to the database
    const res = await api.post("author/flashcards", {
      front,
      back,
      hint,
      note_id,
    });
    const flashcard_id = res.data.id;
    // then update in the store with the database ID
    commit("updateTempFlashcardWithId", { note_id, temp_id, flashcard_id });
  },
  deleteFlashcard({ commit, dispatch }, { flashcard_id, note_id }) {
    commit("deleteFlashcardFromStore", { flashcard_id, note_id });
    api.delete(`author/flashcards/${flashcard_id}`);
  },
};

const mutations = {
  setNotes(state, payload) {
    state.notes = payload.notes;
  },
  focusNote(state, note_id) {
    state.focusedNote = findNoteIdIndex(note_id, state.notes);
  },
  updateNote(state, { note_id, body }) {
    const index = findNoteIdIndex(note_id, state.notes);
    state.notes[index].body = body;
    apiUpdateNote(note_id, body); // a throttled version of saving to the DB
  },
  addNoteToStore(state, { chapter_id, after_note, temp_id }) {
    const index = inc(findNoteIdIndex(after_note, state.notes));
    const newNote = {
      note_id: null,
      temp_id,
      chapter_id,
      body: "",
      flashcards: [],
    };
    if (index === 0) state.notes.push(newNote);
    else state.notes = insert(index, newNote, state.notes);
    state.focusedNote = findTempIndex(temp_id, state.notes);
  },
  addFlashcardToStore(state, { front, back, hint, note_id, temp_id }) {
    const noteIndex = findNoteIdIndex(note_id, state.notes);
    state.notes[noteIndex].flashcards.push({
      front,
      back,
      hint,
      note_id,
      temp_id,
    }); // needs to use .push() to maintain reactivity
  },
  deleteNoteFromStore(state, note_id) {
    const index = findNoteIdIndex(note_id, state.notes);
    Vue.set(state.notes[index], "deletedInStore", true);
    state.focusedNote = dec(index);
  },
  updateTempNoteWithId(state, { temp_id, note_id }) {
    const note_index = findTempIndex(temp_id, state.notes);
    Vue.set(state.notes[note_index], "note_id", note_id);
  },
  deleteFlashcardFromStore(state, { flashcard_id, note_id }) {
    const note_index = findNoteIdIndex(note_id, state.notes);
    const flashcards = state.notes[note_index].flashcards;
    const flashcard_index = findIdIndex(flashcard_id, flashcards);
    Vue.set(flashcards[flashcard_index], "deletedInStore", true);
  },
  updateTempFlashcardWithId(state, { note_id, temp_id, flashcard_id }) {
    const note_index = findNoteIdIndex(note_id, state.notes);
    const flashcards = state.notes[note_index].flashcards;
    const flashcard_index = findTempIndex(temp_id, flashcards);
    Vue.set(flashcards[flashcard_index], "id", flashcard_id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
