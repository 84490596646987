// External
import Vue from "vue";
const showdown = require("showdown");
const md = new showdown.Converter();

// Local
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/tailwind.css";
import api from "@/store/api";

Vue.config.productionTip = false;
Vue.prototype.$http = api;
Vue.prototype.$md = md;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
