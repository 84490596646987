<template>
  <div
    v-if="name === 'basic'"
    class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen"
  >
    <primer-logo class="my-10" :to="{ name: 'home' }" />
    <slot />
  </div>
  <div
    v-else-if="name === '2col'"
    class="h-screen flex overflow-hidden bg-white"
  >
    <div class="flex flex-shrink-0 border-r border-gray-200">
      <slot name="sidebar" />
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <div class="min-h-screen p-14">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import PrimerLogo from "@/components/system/PrimerLogo.vue";

export default {
  props: { name: String },
  components: { PrimerLogo },
};
</script>

<style lang="scss" scoped>
</style>
