<template>
  <input
    v-bind:value="value"
    v-on:input="$emit('input', $event.target.value)"
    :type="type"
    :required="required"
    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
  />
</template>

<script>
export default {
  props: ["value", "type", "required"]
};
</script>
