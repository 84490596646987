<template>
  <div>
    <router-link
      :to="{ name: 'new-book' }"
      tag="div"
      class="inline-block font-bold bg-white rounded-xl px-10 py-2 cursor-pointer w-auto hover:bg-overpale"
    >
      New Book
    </router-link>
  </div>
</template>

<script>
import SimpleCard from "@/components/system/SimpleCard.vue";

export default {
  components: { SimpleCard },
};
</script>