<template>
  <div v-show="timeout" class="logout">
    <div class="h1">Logging out...</div>
  </div>
</template>

<script>
export default {
  data() {
    return { timeout: false };
  },
  methods: {
    logout() {
      this.$store.dispatch("setLoggedOut");
      this.$http
        .post("auth/logout")
        .then(() => {
          this.$router.push("/");
        })
        .catch(error => {
          console.log("Cannot logout");
        });
    }
  },
  mounted() {
    this.logout();
    setTimeout(() => {
      this.timeout = true;
    }, 1000);
  }
};
</script>