<template>
  <div class="flex items-center border-b hover:bg-white pr-2">
    <!-- Link -->
    <router-link :to="to" class="flex-grow py-4 pl-2">
      <small-label v-if="book.deleted" class="border border-red mb-3 mr-2"
        >Deleted</small-label
      >
      <small-label
        v-if="book.published"
        class="bg-green-400 text-white mr-2 mb-3"
        >Published</small-label
      >
      <small-label v-else class="border border-green-400 mr-2 mb-3"
        >Draft</small-label
      >
      <div class="font-bold">{{ book.title }}</div>
    </router-link>
    <!-- Menu -->
    <button-round
      @click="toggleMenu"
      class="flex-none ml-4 hover:bg-overpale bg-white"
    >
      <img src="@/assets/img/ellipsis.svg" />
      <menu-list v-if="showMenu">
        <template v-if="!book.deleted">
          <menu-item v-if="!book.logoUrl" class="line-through text-gray-400"
            >Upload logo</menu-item
          >
          <menu-item v-if="book.logoUrl" class="line-through text-gray-400"
            >Remove logo</menu-item
          >
          <menu-item v-if="!book.published" @click="publishBook">
            Publish book
          </menu-item>
          <menu-item v-else @click="unpublishBook">Unpublish book</menu-item>
          <menu-item @click="deleteBook">Delete book</menu-item>
        </template>
        <menu-item v-if="book.deleted" @click="restoreBook">
          Restore book
        </menu-item>
      </menu-list>
    </button-round>
  </div>
</template>

<script>
import ButtonRound from "@/components/system/ButtonRound.vue";
import MenuList from "@/components/system/MenuList.vue";
import MenuItem from "@/components/system/MenuItem.vue";
import SmallLabel from "@/components/system/SmallLabel.vue";

export default {
  props: { book: Object },
  data() {
    return { showMenu: false };
  },
  components: { ButtonRound, MenuList, MenuItem, SmallLabel },
  computed: {
    to() {
      if (this.book.deleted) return { path: `#` };
      else return { path: `/books/${this.book.id}` };
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    publishBook() {
      this.$store.dispatch("publishBook", { id: this.book.id });
    },
    unpublishBook() {
      this.$store.dispatch("unpublishBook", { id: this.book.id });
    },
    deleteBook() {
      this.$store.dispatch("deleteBook", { id: this.book.id });
    },
    restoreBook() {
      this.$store.dispatch("restoreBook", { id: this.book.id });
    },
  },
};
</script>