<template>
  <div>
    <!-- Header for mobile -->
    <div
      class="bg-white flex justify-between lg:hidden py-4 px-10 items-center"
    >
      <div class="flex items-center">
        <router-link to="/" tag="div">
          <button-round class="pr-6 pt-3">
            <img src="@/assets/img/back.svg" />
          </button-round>
        </router-link>
        <div class="font-bold">{{ currentBookTitle }}</div>
      </div>
      <div>
        <button-small class="mr-4" @click="showChapters">Chapters</button-small>
        <button-small @click="showPreview">Preview</button-small>
      </div>
    </div>
    <!-- Three column layout -->
    <div class="flex">
      <!-- Chapter list -->
      <div
        class="column chapters"
        :class="{ 'hidden lg:block': !chaptersVisible }"
      >
        <!-- Mobile close button -->
        <button-round
          class="absolute top-0 right-0 p-2 mr-4 mt-4 lg:hidden"
          @click="closeChapters"
        >
          <img src="@/assets/img/close.svg" />
        </button-round>
        <!-- Back button -->
        <router-link
          to="/"
          class="mb-4 absolute top-8 left-0 hidden lg:block"
          tag="div"
        >
          <button-round class="p-3">
            <img src="@/assets/img/back.svg" />
          </button-round>
        </router-link>
        <div class="text-xl font-bold mb-10">{{ currentBookTitle }}</div>
        <chapters-sidebar :chapters="currentBookChapters"></chapters-sidebar>
      </div>
      <!-- Chapter content -->
      <div class="column flex-1 bg-pale">
        <chapter-notes />
      </div>
      <!-- Preview -->
      <div
        class="column preview"
        :class="{ 'hidden lg:block': !previewVisible }"
      >
        <!-- Mobile close button -->
        <button-round
          class="absolute top-0 right-0 p-2 mr-4 mt-4 lg:hidden z-50"
          @click="closePreview"
        >
          <img src="@/assets/img/close.svg" />
        </button-round>
        <chapter-preview class="py-24 px-12 overflow-y-auto" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PageContainer from "@/components/system/PageContainer.vue";
import ChaptersSidebar from "@/components/books/ChaptersSidebar.vue";
import ChapterNotes from "@/views/ChapterNotes.vue";
import ChapterPreview from "@/components/books/ChapterPreview.vue";
import ButtonRound from "@/components/system/ButtonRound.vue";
import ButtonSmall from "@/components/system/ButtonSmall.vue";

export default {
  components: {
    PageContainer,
    ChaptersSidebar,
    ChapterNotes,
    ChapterPreview,
    ButtonRound,
    ButtonSmall,
  },
  data() {
    return {
      chaptersVisible: false,
      previewVisible: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentBookTitle",
      "currentBookChapters",
      "currentChapterId",
    ]),
  },
  methods: {
    showChapters() {
      this.chaptersVisible = true;
    },
    closeChapters() {
      this.chaptersVisible = false;
    },
    showPreview() {
      this.previewVisible = true;
    },
    closePreview() {
      this.previewVisible = false;
    },
  },
  watch: {
    currentChapterId() {
      this.closeChapters();
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.column {
  @apply p-10;
  @apply min-h-screen;
  @apply max-h-screen;
  @apply overflow-y-auto;
}

.column.chapters,
.column.preview {
  @apply bg-white;
  @apply lg:flex-none;
  @apply w-auto lg:w-1/4;
  @apply fixed z-10 left-4 right-4 top-4 bottom-4 lg:relative lg:top-0 lg:left-0 lg:right-0 lg:bottom-0;
  @apply rounded-xl lg:rounded-none;
  @apply shadow-lg lg:shadow-none;
}

.column.chapters {
  @apply z-10;
}

.column.preview {
  @apply p-0;
  @apply z-20;
}
</style>
