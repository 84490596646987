<template>
  <div>
    <button-small @click="addChapter" class="-ml-4 mb-8">
      Add chapter
    </button-small>
    <chapter-link
      v-for="c in chapters"
      class="-ml-4 -mr-4 px-4 py-2 rounded-lg"
      :key="c.id"
      :b_id="currentBookId"
      :c_id="c.id"
      :selected="c.id === currentChapterId"
      >{{ c.title }}</chapter-link
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ChapterLink from "@/components/ChapterLink.vue";
import ButtonSmall from "@/components/system/ButtonSmall.vue";

export default {
  props: { chapters: Array },
  components: { ChapterLink, ButtonSmall },
  computed: {
    ...mapGetters(["currentBookId", "currentChapterId"]),
  },
  methods: {
    addChapter() {
      this.$store.dispatch("addChapter");
    },
  },
};
</script>