<template>
  <div class="min-h-screen bg-white flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-10 w-auto" src="@/assets/img/logo.png" alt="Primer" />
    </div>

    <div class="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="py-8 px-4 sm:px-10">
        <form v-on:submit="login">
          <div>
            <input-label forInput="email">Username</input-label>
            <div class="mt-1">
              <input-field v-model="username" :required="true" type="email" />
            </div>
          </div>

          <div class="mt-6">
            <input-label forInput="password">Password</input-label>
            <div class="mt-1">
              <input-field v-model="password" :required="true" type="password" />
            </div>
          </div>

          <div class="mt-10">
            <error-text v-if="showErrors">Could not log you in.</error-text>
            <span class="block w-full rounded-md shadow-sm">
              <submit-button>Sign In</submit-button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
const R = require("ramda");
import InputField from "@/components/form/InputField.vue";
import InputLabel from "@/components/form/InputLabel.vue";
import SubmitButton from "@/components/form/SubmitButton.vue";
import ErrorText from "@/components/form/ErrorText.vue";

export default {
  data() {
    return {
      username: "",
      password: "",
      showErrors: false
    };
  },
  components: { InputField, InputLabel, SubmitButton, ErrorText },
  methods: {
    login(e) {
      e.preventDefault();
      this.$http
        .post("auth/authorlogin", {
          username: this.username,
          password: this.password
        })
        .then(response => this.$router.push("/"))
        .catch(err => (this.showErrors = true));
    }
  }
};
</script>
