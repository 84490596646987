<template>
  <div id="app" class="bg-white font-sans text-copydark antialiased">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>