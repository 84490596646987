<template>
  <router-link
    :to="`/books/${b_id}/chapters/${c_id}`"
    :selected="selected"
    :class="{
      'bg-pale font-bold text-black': selected,
    }"
    tag="div"
    class="font-medium focus:outline-none transition ease-in-out duration-150 hover:bg-overpale cursor-pointer"
  >
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    b_id: Number,
    c_id: Number,
    del: Boolean,
    selected: Boolean,
  },
  components: {},
  computed: {
    clickable() {
      return Boolean(this.toName || this.to);
    },
    routerDestination() {
      if (this.to) return this.to;
      else if (this.toName) return { name: this.toName };
      else return {};
    },
  },
};
</script>

<style scoped>
</style>