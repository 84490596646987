<template>
  <div class="add-flashcard">
    <div class="mb-2">
      <textarea
        v-model="front"
        rows="1"
        ref="question"
        placeholder="Question"
        class="field"
      />
      <textarea
        v-model="back"
        rows="1"
        ref="answer"
        placeholder="Answer"
        class="field"
      />
      <textarea
        v-model="hint"
        rows="1"
        ref="hint"
        placeholder="Hint"
        class="field"
      />
      <div class="flex justify-end">
        <div class="button mt-4" @click="add">Add Flashcard</div>
      </div>
    </div>
  </div>
</template>

<script>
import autosize from "autosize";

export default {
  props: { note_id: Number },
  data() {
    return { front: "", back: "", hint: "" };
  },
  methods: {
    add() {
      this.$store.dispatch("notes/addFlashcard", {
        front: this.front,
        back: this.back,
        hint: this.hint,
        note_id: this.note_id,
      });
      this.front = "";
      this.back = "";
      this.hint = "";
      this.$nextTick(() => {
        this.updateAutosize();
        this.$refs.question.focus();
      });
    },
    updateAutosize() {
      autosize.update(this.$refs.question);
      autosize.update(this.$refs.answer);
      autosize.update(this.$refs.hint);
    },
  },
  computed: {},
  mounted() {
    autosize(this.$refs.question);
    autosize(this.$refs.answer);
    autosize(this.$refs.hint);
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.add-flashcard {
  @apply mb-4;
}
.field {
  @apply block;
  @apply w-full;
  @apply border;
  @apply border-b-0;
  @apply rounded-none;
  @apply p-2;
  &:first-of-type {
    @apply rounded-tr;
    @apply rounded-tl;
  }
  &:last-of-type {
    @apply border-b;
    @apply rounded-br;
    @apply rounded-bl;
  }
}
</style>