<template>
  <label :for="forInput" class="block text-sm font-medium leading-5 text-gray-700">
    <slot />
  </label>
</template>

<script>
export default {
  props: ["forInput"]
};
</script>
