<!-- This should probably be called BookChapter -->
<template>
  <div v-if="notes" class="chapter-notes">
    <!-- HEADER -->
    <chapter-header class="mb-8" />
    <!-- NOTES -->
    <div class="flex">
      <div class="flex-1" v-if="notes.length">
        <input-note
          class="note"
          v-for="(n, i) in notes"
          :key="n.note_id"
          :note="n"
          :number="i + 1"
          :focused="i === focusedNote"
        />
        <button-small @click="addNote" class="mt-8">Add note</button-small>
        <div class="hint text-sm mt-4 ml-0 font-bold text-gray-400">
          Press option + enter to create a new note
        </div>
      </div>
      <!-- ADD FIRST NOTE -->
      <button-small v-else @click="addNote" class="">
        Add first note
      </button-small>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Components
import InputNote from "@/components/InputNote.vue";
import ChapterHeader from "@/components/books/ChapterHeader.vue";
import ButtonSmall from "@/components/system/ButtonSmall.vue";

export default {
  components: { InputNote, ChapterHeader, ButtonSmall },
  computed: {
    ...mapGetters("notes", ["notes", "focusedNote"]),
    c_id() {
      return this.$route.params.c_id;
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch("notes/loadNotes", { chapter_id: this.c_id });
    },
    addNote() {
      this.$store.dispatch("notes/addNote", { chapter_id: this.c_id });
    },
  },
  mounted() {
    this.refresh();
  },
  watch: {
    $route(to, from) {
      this.refresh();
    },
  },
};
</script>

<style lang="scss" scoped>
.chapter-notes {
  padding-bottom: 100px;
}

.note {
  @apply overflow-hidden;
}
</style>